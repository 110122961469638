import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ControlledCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Carousel
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="50"
      data-aos-duration="1000"
      activeIndex={index}
      onSelect={handleSelect}
      className="mb-10"
    >
      {/* First Item */}
      <Carousel.Item className="h-[600px] bg-carousel-1 bg-no-repeat bg-center bg-cover">
        <div className="h-full bg-gradient-to-t from-[#021F59]"></div>
        <Carousel.Caption className="h-full flex flex-col justify-center">
          <h2 className="text-4xl font-medium underline lg:text-4xl">
            Utilities
          </h2>
          <p className="text-lg py-10 md:text-xl lg:text-xl max-w-2xl mx-auto">
            JP Direct Logistics specializes in transportation and logistics for
            electric power, natural gas, steam supply, water supply, and sewage
            removal, as well as construction. Contact us to learn how we can
            handle your shipment.
          </p>
          <Link to="/services">
            <button className="bg-secondary-color w-32 h-12 mx-auto text-white rounded-lg uppercase text-sm font-bold hover:bg-[#F5DE67] lg:w-32 lg:h-12 lg:text-m">
              Learn More
            </button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      {/* Second Item */}
      <Carousel.Item className="h-[600px] bg-carousel-2 bg-no-repeat bg-center bg-cover ">
        <div className="h-full bg-gradient-to-t from-[#F2CB05]"></div>
        <Carousel.Caption className="h-full flex flex-col justify-center">
          <h2 className="text-4xl font-medium underline">Building Materials</h2>
          <p className="text-lg py-10 md:text-xl lg:text-xl max-w-2xl  mx-auto">
            JP Direct Logistics specializes in transporting roofing materials,
            bulk construction materials, concrete mixtures, and even furniture
            and household goods. Contact us to learn how we can help you.
          </p>
          <Link to="/services">
            <button className="bg-primary-color w-32 h-12 mx-auto text-white rounded-lg uppercase text-sm font-bold hover:bg-[#3056A1] lg:w-32 lg:h-12 lg:text-m">
              Learn More
            </button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      {/* Third Item */}
      <Carousel.Item className="h-[600px] bg-carousel-3 bg-no-repeat bg-center bg-cover ">
        <div className="h-full bg-gradient-to-t from-[#021F59]"></div>
        <Carousel.Caption className="h-full flex flex-col justify-center">
          <h2 className="text-4xl font-medium underline">General Freight</h2>
          <p className="text-lg py-10 md:text-xl lg:text-xl max-w-2xl  mx-auto">
            JP Direct Logistics specializes in transporting palletized goods,
            agricultural products, non-ferrous metals, containers, automotive
            freight, and much more. Contact us to learn how we can help you.
          </p>
          <Link to="/services">
            <button className="bg-secondary-color w-32 h-12 mx-auto text-white rounded-lg uppercase text-sm font-bold hover:bg-[#F5DE67] lg:w-32 lg:h-12 lg:text-m">
              Learn More
            </button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default ControlledCarousel;
