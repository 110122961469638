import React from 'react';
import { FaCalculator, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const QuickLinks = () => {
  return (
    <>
      <div className="bg-primary-color h-28 flex justify-around items-center">
        <Link className="flex flex-col items-center" to="contact">
          <FaCalculator className="text-white text-2xl cursor-pointer" />
          <p className="text-white text-m font-bold">Get a quote</p>
        </Link>

        <Link className="flex flex-col items-center" to="contact">
          <FaPhone className="text-white text-2xl" />
          <p className="text-white text-m font-bold">Give us a call</p>
        </Link>
        <Link className="flex flex-col items-center" to="contact">
          <FaMapMarkerAlt className="text-white text-2xl" />
          <p className="text-white text-m font-bold">Location</p>
        </Link>
      </div>
    </>
  );
};
