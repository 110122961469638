import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo2 from '../../assets/images/Logo2.png';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // When ever the navbar is clicked change the state of the navbar to open.
  const openNavbarClick = () => {
    setIsOpen(!isOpen);
  };

  const resetNavBarClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {/* Mobile Approach */}
      <div className="bg-primary-color fixed w-full top-0 z-20">
        <div className="container mx-auto h-24 s px-6 flex justify-between items-center">
          <div className="text-white text-3xl">
            <Link to="/">
              <img
                className="h-20 w-30 lg:h-32 lg:w-30"
                src={Logo2}
                alt="JP Direct Logo"
              />
            </Link>
          </div>
          <div className="hidden md:flex md:justify-evenly md:items-center text-white ">
            <Link
              className="text-sm p-4 uppercase font-regular hover:text-secondary-color"
              to="/"
            >
              Home
            </Link>
            <Link
              className="text-sm p-4 uppercase font-regular hover:text-secondary-color"
              to="about"
            >
              About Us
            </Link>
            <Link
              className="text-sm p-4 uppercase font-regular hover:text-secondary-color"
              to="services"
            >
              Services
            </Link>
            <Link
              className="text-sm p-4 uppercase  font-regular hover:text-secondary-color"
              to="contact"
            >
              Contact Us
            </Link>
          </div>
          <div onClick={openNavbarClick} className="block md:hidden">
            {!isOpen ? (
              <FaBars className="text-white text-3xl cursor-pointer" />
            ) : (
              <FaTimes className="text-white text-3xl cursor-pointer" />
            )}
          </div>
          <div
            className={
              isOpen
                ? 'h-screen fixed left-1/4 w-9/12 top-20 bg-primary-color ease-in-out duration-500'
                : 'h-screen fixed left-[100%] w-full bg-primary-color top-20 ease-in-out duration-500'
            }
          >
            <div className="h-96">
              <div className="flex flex-col items-center justify-between my-32 h-full text-white">
                <Link
                  onClick={resetNavBarClick}
                  className="text-xl uppercase  font-regular hover:text-secondary-color"
                  to="/"
                >
                  Home
                </Link>
                <Link
                  onClick={resetNavBarClick}
                  className="text-xl uppercase  font-regular hover:text-secondary-color"
                  to="about"
                >
                  About Us
                </Link>
                <Link
                  onClick={resetNavBarClick}
                  className="text-xl uppercase font-regular hover:text-secondary-color"
                  to="services"
                >
                  Services
                </Link>
                <Link
                  onClick={resetNavBarClick}
                  className="text-xl uppercase font-regular hover:text-secondary-color"
                  to="contact"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isOpen
              ? 'relative right-3/4 ease-in-out duration-500'
              : 'relative  right-0 ease-in-out duration-500'
          }
        ></div>
      </div>
    </>
  );
};
