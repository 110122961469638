import { React, useEffect } from 'react';
import {
  FaMapMarkerAlt,
  FaPhone,
  FaFacebook,
  FaInstagram,
} from 'react-icons/fa';
import Logo2 from '../../assets/images/Logo2.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Footer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-primary-color h-full py-10 w-full flex flex-col justify-around items-center md:grid md:grid-cols-2 md:gap-4 overflow-x-hidden"
      >
        <div className="w-full flex flex-col justify-center items-center py-4">
          <h3 className="text-secondary-color text-xl font-bold uppercase underline py-2">
            Jp Direct Logistics LLC
          </h3>
          <p className="flex text-white items-center text-lg">
            <FaMapMarkerAlt className="mx-2" /> 14738 Walker Rd, Conroe TX,
            77308
          </p>
          <p className="flex items-center text-white text-lg">
            <FaPhone className="mx-2" /> 346-932-5966
          </p>
        </div>
        <div className="w-full flex flex-col justify-center items-center py-4">
          <h3 className="text-secondary-color text-xl font-bold uppercase underline py-2">
            Socials
          </h3>
          <div className="flex text-white">
            <FaFacebook className="text-2xl" />
            <FaInstagram className="text-2xl mx-1" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center py-4">
          <h3 className="text-secondary-color text-xl uppercase underline font-bold py-2">
            Hours of operations
          </h3>
          <p className="text-white text-xl">Available 24/7, 7 days a week!</p>
        </div>
        <div className="text-center py-4">
          <img className="h-40 mx-auto" src={Logo2} alt="JP Direct Logo" />
        </div>
      </div>
      <p className="text-center bg-primary-color text-white">
        &copy; Copyright 2023 JP Direct Logistics LLC. All rights reserved
      </p>
    </div>
  );
};
