import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useForm, ValidationError } from '@formspree/react';
import { Footer } from '../components/Footer/Footer';
import ControlledCarousel from '../components/Carousel/ControlledCarousel';
import { Banner } from '../components/Banner/Banner';
import Logo2 from '../assets/images/Logo2.png';

export const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [state, handleSubmit] = useForm('mdojrleo');
  if (state.succeeded) {
    return <Contact />;
  }

  return (
    <>
      <ControlledCarousel />
      <div
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="h-full container my-20 mx-auto flex flex-col justify-center items-center lg:flex lg:flex-row-reverse"
      >
        <div className="flex flex-col justify-evenly h-[58vh]">
          <h1 className="text-6xl text-center font-bold text-secondary-color py-4 lg:text-left lg:text-5xl lg:mx-3">
            For more information contact us!
          </h1>
          <p className="text-m text-center tracking-wide leading-8 lg:mx-3">
            "We are here to serve you and ensure that your shipment is delivered
            on time. Don't hesitate to give us a call – we are available 24/7, 7
            days a week!"
          </p>
          <img className="h-40 mx-auto" src={Logo2} alt="JP Direct Logo" />
        </div>
        <form
          className="border w-full h-[60vh] flex max-w-4xl flex-col bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          method="POST"
          onSubmit={handleSubmit}
        >
          <label className="text-xl tracking-wider" htmlFor="name">
            Full Name
          </label>
          <input
            className="border h-11 rounded"
            id="name"
            type="text"
            name="name"
            required
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />

          <label className="text-xl tracking-wider" htmlFor="email">
            Email Address
          </label>
          <input
            className="border h-11 rounded"
            id="email"
            type="email"
            name="email"
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          <label className="text-xl tracking-wider" htmlFor="message">
            Message
          </label>
          <textarea
            className="border h-64 rounded mb-7"
            id="message"
            name="message"
            required
          ></textarea>
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />

          <button
            className="bg-secondary-color w-64 h-12 mx-auto font-bold text-white rounded-lg uppercase text-sm hover:bg-[#f6e79e] lg:w-40 lg:h-16 lg:text-lg"
            type="submit"
            disabled={state.submitting}
          >
            Submit
          </button>
          <ValidationError errors={state.errors} />
        </form>
      </div>
      <Banner />
      <Footer />
    </>
  );
};
