import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Banner = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="h-24 flex justify-center items-center"
      >
        <h2 className="py-3 text-2xl text-center font-bold lg:text-3xl">
          Let <span className="text-secondary-color">JP Direct Logistics</span>{' '}
          help you with your next shipment!
        </h2>
      </div>
    </>
  );
};
