import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ControlledCarousel from '../components/Carousel/ControlledCarousel';
import { FaTruck } from 'react-icons/fa';
import { Banner } from '../components/Banner/Banner';
import { Footer } from '../components/Footer/Footer';
import { GoogleMaps } from '../components/GoogleMaps/GoogleMaps';
import service1 from '../assets/images/services-1.jpg';
import service2 from '../assets/images/services-2.jpg';
import service3 from '../assets/images/services-3.jpg';

export const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <ControlledCarousel />
      <div className="overflow-x-hidden">
        <div className="container h-full  flex flex-col items-center">
          {/* lg:grid lg:grid-cols-2 lg:gap-4 */}
          <div className="py-16 h-full flex flex-col lg:grid">
            <h2 className="text-3xl text-secondary-color font-bold underline py-3 text-center lg:text-left lg:text-3xl">
              Our Services
            </h2>
            <div className="flex flex-col justify-center ">
              <div className="lg:max-w-6xl py-4 text-left md:flex ">
                <p
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  className="pb-10 md:w-full leading-9 "
                >
                  JP Direct Logistics LLC is a trucking and logistics company
                  that provides transportation services for a variety of
                  industries. The company's fleet of trucks is equipped to
                  handle a range of cargo, from utility equipment to
                  construction materials and general freight. With a commitment
                  to safety and customer satisfaction, JP Direct Logistics is
                  able to provide reliable and efficient delivery services for
                  its customers.
                </p>
                <div className="w-full">
                  <img
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    className="rounded-xl"
                    src={service1}
                    alt="service delivery truck"
                  />
                </div>
              </div>
              <div className="lg:max-w-6xl py-4 text-left md:flex md:flex-row-reverse">
                <p
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  className="pb-10 md:w-full leading-9 px-3"
                >
                  JP Direct Logistics prides itself on its experienced and
                  dedicated team of drivers and logistics professionals. The
                  company regularly trains its employees on safety protocols and
                  best practices in the trucking and logistics industry. This
                  commitment to employee development allows JP Direct Logistics
                  to provide the highest level of service to its customers.
                </p>
                <div className="w-full">
                  <img
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    className="rounded-xl md:w-full"
                    src={service2}
                    alt="service delivery truck"
                  />
                </div>
              </div>
              <div className="lg:max-w-6xl py-4 text-left md:flex">
                <p
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  className="pb-10 md:w-full leading-9 px-3"
                >
                  Whether you need to transport a single load or require a
                  comprehensive logistics solution, JP Direct Logistics has the
                  expertise and resources to help. Some of the services we offer
                  include: <strong>Transportation services</strong>
                  {', '}
                  <strong>Order fulfillment</strong> and
                  {', '}
                  <strong>Cross-docking</strong> .Our team of experienced
                  professionals is dedicated to providing personalized solutions
                  that meet the unique needs of our customers, ensuring that
                  their goods are delivered on time and in good condition.
                  Contact us today to learn more about how JP Direct Logistics
                  can support your business.
                </p>
                <div className="w-full">
                  <img
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    className="rounded-xl md:w-full"
                    src={service3}
                    alt="service delivery truck"
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="flex items-center text-xl">
            Hiring Drivers! <FaTruck className="mx-2" />
          </p>
        </div>
      </div>
      <GoogleMaps />
      <Banner />
      <Footer />
    </>
  );
};
