import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const GoogleMaps = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="py-20 mt-20 w-full bg-primary-color overflow-x-hidden">
        <iframe
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          title="Google Maps"
          className="container mx-auto"
          width="100%"
          height="700px"
          style={{ border: 0 }}
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/view?zoom=11&center=30.3119%2C-95.4561&key=AIzaSyBcyhRq-LjsKSnQT3k5Z3k8apJjCHMx1sU"
        />
      </div>
    </>
  );
};
