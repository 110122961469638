import { React, useEffect } from 'react';
import Logo2 from '../../assets/images/Logo2.png';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const AboutSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="flex justify-center mb-48 overflow-x-hidden">
        <div className="container mx-auto h-full text-center lg:text-left lg:flex lg:items-center lg:justify-center">
          <img
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            className="h-60 mx-auto"
            src={Logo2}
            alt="JP Direct Logo"
          />
          <div
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            className="flex flex-col items-center max-w-2xl mx-auto"
          >
            <h2 className="text-center text-3xl lg:text-4xl pb-4 underline text-secondary-color">
              About us
            </h2>
            <p className="text-gray-700 text-base mb-4 lg:text-left lg:text-lg">
              JP Direct Logistics LLC is a trucking and logistics company that
              provides transportation and distribution services for a wide range
              of industries. The company is known for its commitment to safety,
              efficiency, and customer satisfaction. With a fleet of modern,
              well-maintained trucks and experienced drivers, JP Direct
              Logistics is able to handle shipments of all sizes and types,
              including hazardous materials. Whether you need to transport a
              single pallet or manage your entire supply chain, JP Direct
              Logistics has the expertise and resources to help.
            </p>
            <p className="text-gray-700 text-base mb-4 text-center lg:text-left font-bold lg:text-lg">
              Contact us for more information!
            </p>
            <Link to="/about">
              <button className="bg-primary-color w-32 h-12 mx-auto text-white rounded-lg uppercase text-sm font-bold hover:bg-[#3056A1] llg:w-32 lg:h-12  lg:text-m">
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
