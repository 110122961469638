import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaTruck } from 'react-icons/fa';
import { Banner } from '../components/Banner/Banner';
import ControlledCarousel from '../components/Carousel/ControlledCarousel';
import { Footer } from '../components/Footer/Footer';
import { GoogleMaps } from '../components/GoogleMaps/GoogleMaps';
import Logo2 from '../assets/images/Logo2.png';
export const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <ControlledCarousel />
      <div className="overflow-x-hidden">
        <div className="container h-full  flex flex-col items-center lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="text-center">
            <img
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="h-60 mx-auto"
              src={Logo2}
              alt="JP Direct Logo"
            />
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            className="py-16 h-full flex flex-col text-center"
          >
            <h2 className="text-3xl text-secondary-color font-bold underline py-3 lg:text-left">
              JP Direct Logistics LLC
            </h2>
            <p className="text-m leading-8 font-bold tracking-wide w-full text-left ">
              JP Direct Logistics LLC a family owned company based out of
              Conroe, TX founded in 2021
            </p>
            <br />
            <br />
            <p className="text-m leading-8 tracking-wide w-full text-left">
              Our small family-owned company is just starting out, but we have
              big dreams and a lot of ambition. We are a tight-knit team of
              family members who are passionate about what we do, and we are
              committed to providing high-quality products and services to our
              customers. Despite our small size, we have a lot of experience and
              expertise in our field, and we are confident in our ability to
              deliver excellent results.
            </p>
            <p className="text-m leading-8 tracking-wide w-full text-left">
              As a family-owned business, we value the importance of building
              strong relationships with our customers. We understand that trust
              is a crucial part of any successful business, and we work hard to
              earn the trust and loyalty of our customers. We are committed to
              providing personalized service and attention to detail, and we are
              always available to answer any questions or concerns that our
              customers may have. We believe that by treating our customers like
              family, we can build long-lasting relationships that will help us
              to grow and thrive.
            </p>
          </div>
          <p className="flex items-center text-xl">
            Hiring Drivers! <FaTruck className="mx-2" />
          </p>
        </div>
      </div>
      <GoogleMaps />
      <Banner />
      <Footer />
    </>
  );
};
