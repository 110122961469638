import { React, useEffect } from 'react';
import safety from '../../assets/images/Safety.jpeg';
import Trustworthy from '../../assets/images/Trustworthy.jpeg';
import OnTime from '../../assets/images/OnTime.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { FaHandshake, FaHardHat, FaClock } from 'react-icons/fa';

export const CardSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className=" container flex flex-col items-center justify-around overflow-x-hidden">
        <div
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          className="flex flex-col md:flex-row md:h-96 w-full rounded-lg border"
        >
          <img
            className=" w-full h-80 md:h-auto object-cover md:w-80 lg:w-[40vw] rounded-t-lg md:rounded-none md:rounded-l-lg"
            src={safety}
            alt="Man checking tire pressure"
          />
          <div className="p-6 flex flex-col justify-end md:justify-center">
            <h5 className="text-secondary-color text-xl text-center font-medium mb-2 lg:text-2xl underline pb-2">
              Safety
            </h5>
            <p className="text-black text-base mb-4 text-left">
              JP Direct Logistics LLC is committed to road safety in all of its
              operations. The company has implemented strict safety protocols
              and regularly trains its drivers on best practices for safe
              driving. Additionally, the company regularly maintains and
              inspects its fleet of trucks to ensure they are in top operating
              condition. Overall, the company's commitment to road safety sets
              it apart in the trucking and logistics industry.
            </p>
            <FaHardHat className="text-secondary-color text-4xl w-full" />
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          className=" flex flex-col md:flex-row-reverse md:h-96 rounded-lg bg-primary-color shadow-lg my-20"
        >
          <img
            className=" w-full h-80 md:h-auto object-cover md:w-80 lg:w-[40vw] rounded-t-lg md:rounded-none md:rounded-r-lg"
            src={Trustworthy}
            alt="Two people shaking hands"
          />
          <div className="p-6 flex flex-col justify-start md:justify-center">
            <h5 className="text-secondary-color text-xl text-center font-medium mb-2  lg:text-2xl underline pb-2">
              Trustworthy
            </h5>
            <p className="text-white text-base mb-4 text-left">
              As a trustworthy family-owned trucking and logistics company, we
              prioritize open and honest communication with our customers. We
              are transparent about any potential issues or delays that may
              arise, and we take personal accountability for ensuring the safe
              and timely delivery of goods. This level of trust helps us to
              build strong relationships with our customers and ensures the
              long-term success of our business.
            </p>
            <FaHandshake className="text-secondary-color text-4xl w-full" />
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          className="flex flex-col md:flex-row md:h-96 w-full rounded-lg border"
        >
          <img
            class=" w-full h-80 md:h-auto object-cover md:w-80 lg:w-[40vw] rounded-t-lg md:rounded-none md:rounded-l-lg"
            src={OnTime}
            alt="A semi truck driving fast"
          />
          <div className="p-6 flex flex-col justify-end md:justify-center">
            <h5 className="text-secondary-color text-xl text-center font-medium mb-2 lg:text-2xl underline pb-2">
              On Time
            </h5>
            <p className="text-black text-base mb-4 text-left">
              As a trucking and logistics company, we take pride in providing
              on-time deliveries to our customers. We understand the importance
              of timely deliveries in the fast-paced world of business, and we
              work hard to ensure that every shipment arrives at its destination
              on time. Our team of experienced drivers and dispatchers use the
              latest technology to track and manage our fleet of trucks,
              allowing us to provide real-time updates to our customers and make
              any necessary adjustments to ensure timely arrivals.
            </p>
            <FaClock className="text-secondary-color text-4xl w-full" />
          </div>
        </div>
      </div>
    </>
  );
};
