import { React, useEffect } from 'react';
import { AboutSection } from '../AboutSection/AboutSection';
import { Banner } from '../Banner/Banner';
import { CardSection } from '../CardSection/CardSection';
import ControlledCarousel from '../Carousel/ControlledCarousel';
import { Footer } from '../Footer/Footer';
import { GoogleMaps } from '../GoogleMaps/GoogleMaps';
import { QuickLinks } from '../Quick-Links/QuickLinks';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="relative bg-hero-background-image h-[90vh] bg-center bg-no-repeat bg-cover ">
        <div className="h-full bg-gradient-to-t from-[#021F59]">
          <div
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            className="text-center flex flex-col justify-center m-auto h-[100%]"
          >
            <h1 className="text-secondary-color text-4xl font-bold md:text-5xl lg:text-6xl">
              JP DIRECT LOGISTICS LLC
            </h1>
            <p className="text-white text-xl py-7 max-w-2xl mx-auto md:text-2xl md:max-w-2xl md:mx-auto lg:text-lg">
              A Texas based company that is committed to road-safety, on-time
              delivery, and great customer communication in all of its
              operations.
            </p>
            <Link to="/services">
              <button className="bg-secondary-color w-32 h-12 mx-auto font-bold text-white rounded-lg uppercase text-sm hover:bg-[#f6e79e] lg:w-32 lg:h-13 lg:text-m">
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <QuickLinks />
      <ControlledCarousel />
      <AboutSection />
      <CardSection />
      <GoogleMaps />
      <Banner />
      <Footer />
    </>
  );
};

export default Hero;
